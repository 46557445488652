<p class="not-saved-block" *ngIf="hasChanged">Изменения не сохранены</p>

<div class="content-wrap empty" *ngIf="!IsSelected && !ItemData" [innerHTML]=" 'Нет текста'"></div>
<div class="content-wrap inner-text disable-copy" *ngIf="!IsSelected && ItemData" [innerHTML]="ItemData|safe"></div>

<div *ngIf="IsSelected" (click)="$event.stopPropagation()">

    <ckeditor
            style="cursor: text"
            [editor]="Editor1"
            [config]="config"
            [(ngModel)]="ItemData"
    ></ckeditor>
    <br>
    <div class="buttons">
        <div class="button" (click)="changeText()">Сохранить текст</div>
        <div class="button" (click)="cancelChangeText()">Вернуть назад</div>
    </div>

</div>




