import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {PartnersService} from "../../../../shared/services/partners.service";
import {tap} from "rxjs/operators";
import {LoadingService} from "../../../../shared/services/loading.service";
import {HTTPService} from "../../../../shared/services/main.service";
import {NgClass, NgIf} from "@angular/common";
import {PreloaderComponent} from "../../../../shared/ui/preloader/preloader.component";

@Component({
  selector: 'app-partners-item',
  templateUrl: './partners-item.component.html',
  styleUrls: ['./partners-item.component.scss'],
  imports: [
    NgClass,
    NgIf,
    PreloaderComponent
  ],
  standalone: true
})
export class PartnersItemComponent implements OnInit, OnChanges {
  @Input() id = 0;
  mainService = inject(HTTPService)
  role: string = this.mainService.getFormattedProfileType();
  @Input() name: string = '';
  @Input() bonus: string = '';
  @Input() description: string = '';
  @Input() contacts: any = '';
  @Input() flag: boolean = false;
  @Input() isSystem: boolean = false;
  @Input() tags: string[] = [];
  @Input() viewMode = false;

  //@Input() showContact: boolean = true;

  @Input() isBlock: boolean = false;

  @Output() getContact = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onShow = new EventEmitter();

  LoadingImage = false;
  @Input() ContactLoading = false;

  image = '';

  widthBlock = null;

  constructor(
    private partnerService: PartnersService,
    public loadingService: LoadingService
  ) {
  }


  ngOnInit(): void {
    /*this.GetImage().subscribe(() => {
      this.LoadingImage = false;
    });*/
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['id']){
      if(changes['id']['previousValue'] != changes['id']['currentValue']) {
        this.LoadingImage = true;
        this.GetImage().subscribe(() => {
          this.LoadingImage = false;
        });
      }
    }


  }



  GetImage() {
    this.LoadingImage = true;
    return this.partnerService.GetPartnerImage(this.id)
      .pipe(
        tap((res) => {
          this.image = res;

        })
      )
  }

  GetContact(event:any) {
    event.stopPropagation();
    if(this.contacts === null){
      this.getContact.emit(this.id);
    }

  }

  isLink(value: string): boolean {
    const pattern = /^(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?$/;
    return pattern.test(value);
  }

  openLink(url: any) {
    window.open(url, '_blank');
  }

  EditPartner() {
    this.onEdit.emit(
      {
        id: this.id,
        image: this.image
      }
    )

  }

  DeletePartner() {
    if (confirm('Уверены, что хотите удалить партнёра?')) {
      this.onDelete.emit(this.id);
    }

  }

  ShowSystemPartner() {
    if (confirm(!this.flag ? 'Вы уверены, что хотите опубликовать партнёра?' : 'Вы уверены, что хотите скрыть партнёра?')) {
      this.onShow.emit(this.id)
    }

  }


}
